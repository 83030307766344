import DatePicker from 'vue2-datepicker';
import Organization from '../../../admin/organization';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DefineProject from '../../defineProjects';
import ProjectTowers from '../../projectTower';
import ProjectFloors from '../../projectFloor';
export default {
  name: 'AddProjectUnit',
  props: {
    parentProjectUnitData: Object,
    showDetails: Boolean
  },
  components: {
    DatePicker,
    Organization,
    DefineProject,
    ProjectTowers,
    ProjectFloors
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      masterPrj: {
        value: null,
        text: null
      },
      sector: {
        value: null,
        text: null
      },
      pocket: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floor: {
        value: null,
        text: null
      },
      floorPlan: {
        value: null,
        text: null
      },
      unitDisplayName: null,
      unitName: null,
      unitId: 0,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      version: null,
      active: true
    };
  },
  mounted() {
    if (this.parentProjectUnitData) {
      this.fillDataFromParent();
  } else { this.editMode = true }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditProjectUnit();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.unitId });
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'Organization') {
        return (this.childName = 'Organization');
      } else if (tab === 'DefineProject') {
        return (this.childName = 'Define Project');
      } else if (tab === 'ProjectFloors') {
        return (this.childName = 'Projects Floors');
      } else if (tab === 'ProjectTowers') {
        return (this.childName = 'Project Towers');
      }
    },
    fillDataFromParent() {
      this.masterPrj = {
        value: this.parentProjectUnitData.master_project_id,
        text: this.parentProjectUnitData.master_project_name
      };
      this.sector = {
        value: this.parentProjectUnitData.sector_id,
        text: this.parentProjectUnitData.sector_name
      };
      this.pocket = {
        value: this.parentProjectUnitData.pocket_id,
        text: this.parentProjectUnitData.pocket_name
      };
      this.project = {
        value: this.parentProjectUnitData.project_id,
        text: this.parentProjectUnitData.project_name
      };
      this.tower = {
        value: this.parentProjectUnitData.tower_id,
        text: this.parentProjectUnitData.tower_name
      };
      this.floor = {
        value: this.parentProjectUnitData.floor_id,
        text: this.parentProjectUnitData.floor_name
      };
      this.floorPlan = {
        value: this.parentProjectUnitData.floor_plan_id,
        text: this.parentProjectUnitData.floor_plan_name
      };
      this.unitDisplayName = this.parentProjectUnitData.unit_display_name;
      this.unitId = this.parentProjectUnitData.project_unit_id;
      this.unitName = this.parentProjectUnitData.unit_name;
      this.active = this.parentProjectUnitData.active;
      this.startDate = this.parentProjectUnitData.start_date;
      this.endDate = this.parentProjectUnitData.end_date;
    },
    addEditProjectUnit() {
      const payload = {
        active: this.active,
        floor_id: this.floor.value,
        project_id: this.project.value,
        floor_plan_id: this.floorPlan.value,
        sector_id: this.sector.value,
        pocket_id: this.pocket.value,
        unit_name: this.unitName,
        project_unit_id: this.unitId,
        unit_display_name: this.unitDisplayName,
        start_date: this.startDate,
        end_date: this.endDate
      };
      this.loader = true;
      this.$store
        .dispatch('projects/addEditProjectUnit', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateProjectUnitList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.startDate
      );
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.sector = {
          value: null,
          text: null
        };
        this.pocket = {
          value: null,
          text: null
        };
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.pocket = {
          value: null,
          text: null
        };
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.pocket = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_PROJECT) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else {
        this.floorPlan = {
          value: item.floor_plan_id,
          text: item.floor_plan_name
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.parent_value_set_id = this.masterPrj.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.parent_value_set_id = this.sector.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_PROJECT) {
        this.parent_value_set_id = this.pocket.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    hideModalComponent(flag, component) {
      if (component === 'ProjectTowers' && !this.project.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
      } else if (component === 'ProjectFloors' && !this.tower.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJTOWERMSG);
      }
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    openCloseOu(flag, orgType, component) {
      this.tab = component;
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.setChildName(this.tab);
      this.showModalComponent = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.leName.value = item.org_id;
        this.leName.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.ouName.value = item.org_id;
        this.ouName.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.location.value = item.org_id;
        this.location.text = item.org_name;
      }
      this.showModalComponent = false;
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
    },
    towerDetails(item) {
      this.showModalComponent = false;
      this.tower = {
        value: item.tower_id,
        text: item.tower_name
      };
      this.floor = {
        value: null,
        text: null
      };
    },
    floorDetails(item) {
      this.showModalComponent = false;
      this.floor = {
        value: item.project_floor_id,
        text: item.floor_name
      };
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: null,
          text: null
        };
        this.sector = {
          value: null,
          text: null
        };
        this.pocket = {
          value: null,
          text: null
        };
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: null,
          text: null
        };
        this.pocket = {
          value: null,
          text: null
        };
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.pocket = {
          value: null,
          text: null
        };
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_PROJECT) {
        this.project = {
          value: null,
          text: null
        };
        this.tower = {
          value: null,
          text: null
        };
        this.floor = {
          value: null,
          text: null
        };
      } else {
        this.floorPlan = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
