import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DefineProject from '../defineProjects';
import ProjectTowers from '../projectTower';
import ProjectFloors from '../projectFloor';
import AddProjectUnit from './addProjectUnit';
import ProjectUnitDetails from './projectUnitDetails';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'ProjectUnit',
  watch: {
    currentPage: function() {
      this.getProjectsUnitList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProjectsUnitList();
    }
  },
  props: ['showProjectUnitList', 'prjProjectId', 'prjProjectName'],
  components: {
    DefineProject,
    ProjectTowers,
    ProjectFloors,
    AddProjectUnit,
    ProjectUnitDetails
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      showDetails: false,
      payload: {},
      orgType: null,
      openOuModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      tab: null,
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floor: {
        value: null,
        text: null
      },
      floorPlan: {
        value: null,
        text: null
      },
      active: true,
      unitDisplayName: null,
      unitName: null,
      parentProjectUnitData: null,
      projectUnitData: [],
      projectUnitFields: [
        {
          key: 'project_unit_id',
          class: 'd-none'
        },
        {
          key: 'unit_name'
        },
        {
          key: 'unit_display_name',
          label: 'Unit Display'
        },
        {
          key: 'master_project_id',
          class: 'd-none'
        },
        {
          key: 'master_project_name'
        },
        {
          key: 'sector_id',
          class: 'd-none'
        },
        {
          key: 'sector_name'
        },
        {
          key: 'pocket_id',
          class: 'd-none'
        },
        {
          key: 'pocket_name'
        },
        {
          key: 'project_id',
          class: 'd-none'
        },
        {
          key: 'project_name'
        },
        {
          key: 'tower_id',
          class: 'd-none'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'floor_id',
          class: 'd-none'
        },
        {
          key: 'floor_name'
        },
        {
          key: 'floor_plan_id',
          class: 'd-none'
        },
        {
          key: 'floor_plan_name',
          label: 'Floor Plan'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'active'
        },
        {
          key: 'version'
        }
      ]
    };
  },
  validations: {
    project: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.showProjectUnitList) {
      this.project.text = this.prjProjectName;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'AddProjectUnit');
          this.parentProjectUnitData = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'projects/getProjectsUnitList',
            'project-Unit',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'DefineProject') {
        return (this.childName = 'Define Projects');
      } else if (tab === 'ProjectTowers') {
        return (this.childName = 'Project Towers');
      } else if (tab === 'ProjectFloors') {
        return (this.childName = 'Projects Floors');
      } else if (tab === 'AddProjectUnit') {
        return (this.childName = 'Add Project Unit');
      } else if (tab === 'ProjectUnitDetails') {
        return (this.childName = 'Projects Unit Details');
      }
    },

    getProjectsUnitList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          project_id: this.prjProjectId
            ? this.prjProjectId
            : this.project.value,
          unit_name: this.unitName,
          display_name: this.unitDisplayName,
          tower_id: this.tower.value,
          floor_id: this.floor.value,
          floor_plan_id: this.floorPlan.value,
          active: this.active
        };
        this.loader = true;
        this.$store
          .dispatch('projects/getProjectsUnitList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.projectUnitData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    hideModalComponent(flag, component) {
      if (component === 'ProjectTowers' && !this.project.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
      } else if (component === 'ProjectFloors' && !this.tower.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJTOWERMSG);
      }
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    rowSelected(rowData) {
      this.parentProjectUnitData = rowData;
      this.$emit('selectedPrjUnitName', this.parentProjectUnitData);
      this.showDetails = true;
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
      this.tower = {
        value: null,
        text: null
      };
      this.floor = {
        value: null,
        text: null
      };
    },
    towerDetails(item) {
      this.showModalComponent = false;
      this.tower = {
        value: item.tower_id,
        text: item.tower_name
      };
      this.floor = {
        value: null,
        text: null
      };
    },
    floorDetails(item) {
      this.showModalComponent = false;
      this.floor = {
        value: item.project_floor_id,
        text: item.floor_name
      };
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'prj_floor_plan') {
        this.floorPlan = {
          value: item.floor_plan_id,
          text: item.floor_plan_name
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    closeProjectUnitDetail() {
      this.showDetails = false;
    },
    updateProjectUnitList() {
      this.getProjectsUnitList();
    },
    resetSearchFilters() {
      this.project = {
        value: null,
        text: null
      };
      this.tower = {
        value: null,
        text: null
      };
      this.floor = {
        value: null,
        text: null
      };
      this.floorPlan = {
        value: null,
        text: null
      };
      this.unitDisplayName = null;
      this.unitName = null;
      this.projectUnitData = [];
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'prj_floor_plan') {
        this.floorPlan = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
