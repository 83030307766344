import Specification from './specification';
import AddProjectUnit from '../addProjectUnit';
import UnitArea from './unitArea';
import OtherSpec from './otherSpec';
export default {
  name: 'ProjectUnitDetails',
  props: {
    parentProjectUnitData: Object
  },
  components: {
    Specification,
    AddProjectUnit,
    UnitArea,
    OtherSpec
  },
  data() {
    return {
      actionName: null,
      loader: false,
      activeTab: 'UnitDetails'
    };
  },
  mounted() { },
  methods: {
    selectedTab(tabValue) {
      this.activeTab = tabValue;
    },
    closeTabs() {
      this.$emit('closeProjectUnitDetail');
      this.$emit('updateProjectUnitList');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    }
  }
};