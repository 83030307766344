import appStrings from '@/app/utility/string.utility';
export default {
    name: 'OtherSpec',
    props: {
        parentProjectUnitData: Object
    },
    data() {
        return {
            unsubscribe: null,
            showModalComponent: false,
            showValueSetModal: false,
            editMode: false,
            loader: false,
            showAlert: false,
            isSuccess: false,
            responseMsg: '',
            intervalClass: null,
            unitId: null,
            widthType: null,
            facing: {
                value: null,
                text: null
            },
            direction: {
                value: null,
                text: null
            },
            openSide: {
                value: null,
                text: null
            },
            frontWidth: null,
            frontWidthUnit: {
                value: null,
                text: null
            },
            backWidth: null,
            backWidthUnit: {
                value: null,
                text: null
            }
        }
    },
    mounted() {
        this.getUomClass();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode == true) {
                    this.addEditProjectUnitOtherSpec();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
            }
        });
        this.unitId = this.parentProjectUnitData.project_unit_id;
        this.getProjectUnitOtherSpec();
    },
    methods: {
        getProjectUnitOtherSpec() {
            const payload = {
                prjUnitId: this.unitId
            };
            this.loader = true;
            this.$store.dispatch('projects/getProjectUnitOtherSpec', payload)
                .then(response => {
                    this.loader = false;
                    const results = response.data.data;
                    this.unitId = results.project_unit_id;
                    this.projectUnitOtherSpecId = results.project_unit_other_specification_id;
                    this.facing = {
                        value: results.facing_vset,
                        text: results.facing_value_meaning,
                    };
                    this.direction = {
                        value: results.direction_vset,
                        text: results.open_side_value_meaning,
                    };
                    this.openSide = {
                        value: results.open_side_vset,
                        text: results.open_side_value_meaning,
                    };
                    this.frontWidth = results.front_road_width;
                    this.frontWidthUnit = {
                        value: results.front_road_width_uom_vset,
                        text: results.front_road_width_uom_meaning,
                    };
                    this.backWidth = results.back_road_width;
                    this.backWidthUnit = {
                        value: results.back_road_width_uom_vset,
                        text: results.back_road_width_uom_meaning,
                    };
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        addEditProjectUnitOtherSpec() {
            const payload = {
                back_road_width: this.backWidth,
                back_road_width_uom_vset: this.backWidthUnit.value,
                direction_vset: this.direction.value,
                front_road_width: this.frontWidth,
                front_road_width_uom_vset: this.frontWidthUnit.value,
                project_unit_id: this.unitId,
                facing_vset: this.facing.value,
                open_side_vset: this.openSide.value,
                project_unit_other_specification_id: this.projectUnitOtherSpecId
            };
            this.loader = true;
            this.$store.dispatch('projects/addEditProjectUnitOtherSpec', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.editMode = false;
                        this.showAlert = true;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                        const results = response.data.data;
                        this.projectUnitOtherSpecId = results.project_unit_other_specification_id;
                    }
                    else {
                        this.showAlert = true;
                        this.isSuccess = false;
                        this.responseMsg = response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        getUomClass() {
            this.$store
                .dispatch('template/getLOVBySetType', 'UOM_CLASS')
                .then(response => {
                    if (response.status === 200) {
                        const filterResult = response.data.data;
                        const getValueAndText = filterResult.map(type => {
                            return {
                                value: type.value_code,
                                text: type.value_meaning,
                                detail_id: type.value_set_dtl_id
                            };
                        });
                        this.intervalClass = getValueAndText.filter(item => item.value === 'LEN');
                    }
                });
        },
        selectedvalueSet(item) {
            if (this.vsetCode === 'FACING') {
                this.facing = {
                    value: item.value_code,
                    text: item.value_meaning
                }
            }
            if (this.vsetCode === 'Direction') {
                this.direction = {
                    value: item.value_code,
                    text: item.value_meaning
                }
            }
            if (this.vsetCode === 'Open_side') {
                this.openSide = {
                    value: item.value_code,
                    text: item.value_meaning
                }
            }
            if (this.vsetCode === 'UOM') {
                if (this.widthType === 'frontWidth') {
                    this.frontWidthUnit = {
                        value: item.value_code,
                        text: item.value_meaning
                    }
                }
                else if (this.widthType === 'backWidth') {
                    this.backWidthUnit = {
                        value: item.value_code,
                        text: item.value_meaning
                    }
                }
            }
        },
        openValueSetModal(vsetCode, widthType) {
            if (vsetCode === 'UOM') {
                this.parent_value_set_id = this.intervalClass[0].detail_id;
            }
            this.vsetCode = vsetCode;
            this.widthType = widthType;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode,widthType){
            if (vsetCode === 'FACING') {
                this.facing = {
                    value: null,
                    text: null
                }
            }
            if (vsetCode === 'Direction') {
                this.direction = {
                    value: null,
                    text: null
                }
            }
            if (vsetCode === 'Open_side') {
                this.openSide = {
                    value: null,
                    text: null
                }
            }
            if (vsetCode === 'UOM') {
                if (widthType === 'frontWidth') {
                    this.frontWidthUnit = {
                        value: null,
                        text: null
                    }
                }
                else if (widthType === 'backWidth') {
                    this.backWidthUnit = {
                        value: null,
                        text: null
                    }
                }
            }
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
}